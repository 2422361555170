import Logo from "@cospex/client/components/Logo";
import Menu from "@cospex/client/components/Menu";
import { useCustomization } from "@cospex/client/hooks/useCustomization";
import { Box, Container, Stack, SxProps } from "@mui/material";
import { useLocation } from "react-router-dom";

interface IMenuOptions {
  dashboardHref: string;
  iconColor?: string;
}

interface IHeader {
  menu: IMenuOptions;
  sx?: SxProps;
  hidden?: boolean;
}

const Header = ({ menu, sx = {}, hidden }: IHeader) => {
  const location = useLocation();
  const { logo } = useCustomization();
  return (
    !hidden && (
      <Box
        sx={{
          position: "relative",
          zIndex: 10,
          py: {
            xs: 2,
            md: 4,
          },
          overflow: "hidden",
          ...sx,
        }}
      >
        <Container sx={{ height: "40px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
          >
            <Logo logoPath={logo.src} sx={logo.sx} textOnly={logo.textOnly} />
            {!location.pathname.includes("payment") && (
              <Menu
                dashboardHref={menu.dashboardHref}
                iconColor={menu.iconColor}
              />
            )}
          </Stack>
        </Container>
      </Box>
    )
  );
};

export default Header;
